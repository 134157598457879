/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chart': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M15.444 3H4.556A1.56 1.56 0 003 4.556v10.888A1.56 1.56 0 004.556 17h10.888A1.56 1.56 0 0017 15.444V4.556A1.56 1.56 0 0015.444 3zm0 12.444H4.556V4.556h10.888zm-9.333-7h1.556v5.444H6.111zm3.111-2.333h1.556v7.778H9.222zm3.111 4.667h1.556v3.111h-1.556z" fill="#009bd4"/>',
    lastMod: '1731744184941.4092'
  }
})
