export const state = () => {
  return {
    subscribedDevices: undefined,
  }
}

export const mutations = {
  setSubscribedDevices(state, subscribedDevices) {
    state.subscribedDevices = subscribedDevices
  },
}

export const getters = {
  getSubscribedDevices(state) {
    return state.subscribedDevices
  },
}

export const actions = {
  async GetSubscribedDevicesData({ commit }, selectedYear) {
    const filterParams = { Year: selectedYear }
    await this.$axios({
      method: 'get',
      url: 'organizations/0/subscribed-devices/',
      params: Object.assign({}, filterParams),
    })
      .then((response) => {
        commit('setSubscribedDevices', response.data.Result)
      })
      .catch((error) => {
        if (!error.response) {
          return error
        }
        if (error.response.data.GlobalError) {
          this.GlobalError = error.response.data.GlobalError
        }
      })
  },
}
