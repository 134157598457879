import merge from 'lodash/merge'

export default ({ app, store }, inject) => {
  const formHelpers = {
    initFormsReactData(componentRefer, formName) {
      componentRefer.$set(
        componentRefer.$data,
        formName,
        componentRefer.forms[formName]()
      )
      const errorFields = { form: true }
      Object.keys(componentRefer.forms[formName]()).forEach((key) => {
        errorFields[key] = ''
      })
      componentRefer.$set(componentRefer.formErrors, formName, errorFields)
    },
    initLazyReactData(componentRefer, fieldName, patternData) {
      componentRefer.$set(componentRefer.$data, fieldName, patternData)
    },
    resetForm(componentRefer, formName) {
      componentRefer.$data[formName] = componentRefer.forms[formName]()
      componentRefer.$refs[formName] &&
        componentRefer.$refs[formName].resetValidation()
    },
    onCatchError(componentRefer, error, formName = '') {
      if (!error.response) {
        return
      }
      let errorMsg = ''
      if (formName && error.response.data.FieldsError) {
        componentRefer.formErrors[formName] = {
          ...componentRefer.formErrors[formName],
          ...error.response.data.FieldsError,
        }
        errorMsg = componentRefer.$t('global.message.incorrectFields')
      }
      if (error.response.data.GlobalError) {
        componentRefer.GlobalError = error.response.data.GlobalError
        errorMsg += ' ' + error.response.data.GlobalError
      }
      if (errorMsg) {
        componentRefer.$dialog.message.error(errorMsg)
      }
    },
    merge,
  }
  inject('formHelpers', formHelpers)
}
