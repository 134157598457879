/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'replace': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M10 3.333v-2.5L6.666 4.166l3.333 3.333v-2.5a5 5 0 015 5 4.892 4.892 0 01-.583 2.333l1.217 1.218A6.655 6.655 0 0010 3.333zM10 15a5 5 0 01-5-5 4.892 4.892 0 01.583-2.334L4.366 6.45a6.655 6.655 0 005.633 10.217v2.5l3.334-3.334L10 12.5z" fill="#009bd4"/>',
    lastMod: '1731744184945.4092'
  }
})
