/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu-toggle': {
    width: 24.344,
    height: 17,
    viewBox: '0 0 24.344 17',
    data: '<g fill="none" stroke="#009bd4" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.5.5h13.082M.5 16.5h13.082M.5 8.5h8.994M16.066 8.611l7.778 7.778zm7.779-8L16.066 8.39z"/></g>',
    lastMod: '1731744184945.4092'
  }
})
