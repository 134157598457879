export const state = () => {
  return {
    skippedValues: null,
  }
}

export const mutations = {
  setSkippedValues(state, skippedValues) {
    state.skippedValues = skippedValues
  },
}

export const getters = {
  getSkippedValues(state) {
    return state.skippedValues
  },
}

// export const actions = {
//   setSkipped Values({ context }, skippedValues) {
//     context.commit('setSkippedValues', skippedValues)
//   },
// }
