import Moment from 'moment'
import MomentTimeZone from 'moment-timezone'

window.moment = Moment
MomentTimeZone()

export default ({ $axios, redirect, store, $dialog, app }) => {
  const i18n = store.$i18n
  let isRefreshing = false
  const timezone = window.moment.tz.guess()
  $axios.setHeader('X-Time-Zone', timezone)
  $axios.setHeader('X-Console-Language', app.i18n.localeProperties.code)
  $axios.setHeader('X-Content-Type-Options', 'nosniff')
  $axios.setHeader('X-Frame-Options', 'DENY')
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    $axios.setHeader('X-Console-Language', newLocale)
  }

  // $axios.onRequest((config) => {})

  $axios.onResponseError(async (error) => {
    const code = parseInt(error.response && error.response.status)

    if (!code) {
      $dialog.message.error('Oops, something went wrong.')
    }

    switch (code) {
      case 401:
        if (!isRefreshing) {
          isRefreshing = true
          const originalRequest = { isError: false, response: undefined }
          await $axios
            .$post('/generate-token/', {}, {})
            .then(async (resp) => {
              isRefreshing = false

              await $axios
                .request(error.config)
                .then((resp) => {
                  originalRequest.response = resp
                })
                .catch((err) => {
                  if (err.response.status !== 401) {
                    originalRequest.isError = true
                    originalRequest.response = err
                  }
                })
            })
            .catch(() => {
              isRefreshing = false
              app.$user.clearLocalStorage()
              redirect('/login/')
            })
          if (originalRequest.response) {
            return originalRequest.isError
              ? Promise.reject(originalRequest.response)
              : Promise.resolve(originalRequest.response)
          }
        }
        isRefreshing = false

        throw error
      case 403:
        $dialog.message.error(i18n.t('access_denied.accessNotPermittedMsg'))
        throw error
      case 404:
        $dialog.message.error(i18n.t('validation_new.destinationNotFound'))
        throw error
      case 500:
        $dialog.message.error(
          i18n.t('validation_new.somethingHappenedOnOurServers')
        )
        throw error
      default:
        throw error
    }
  })
}
