export const state = () => ({
  userData: null,
})

export const mutations = {
  setUserData(state, userData) {
    state.userData = userData
  },
}

export const getters = {
  userData(state) {
    return state.userData
  },
}
