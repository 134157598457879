/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'window-x': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><path pid="0" d="M.5.5l15 15M15.5.5l-15 15"/></g></g>',
    lastMod: '1731744184945.4092'
  }
})
