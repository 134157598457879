/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'measurements2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g data-name="Layer 2"><g data-name="Layer 1"><path pid="0" d="M21.24 18.83a10 10 0 10-18.48 0" fill="none" stroke="currentColor" stroke-linecap="square" stroke-linejoin="round" stroke-width="2"/><path pid="1" d="M11.6 13.22a2.38 2.38 0 00-2 2.22 2.4 2.4 0 104.8.29 2.38 2.38 0 00-1-2.09l.4-4.89a.22.22 0 00-.41-.09z" fill="currentColor"/></g></g>',
    lastMod: '1731744184945.4092'
  }
})
