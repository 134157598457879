/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'settings': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0z" fill="none"/><path pid="1" d="M16.192 10.817A6.494 6.494 0 0016.25 10a6.494 6.494 0 00-.058-.817l1.758-1.375a.42.42 0 00.1-.533l-1.666-2.883a.416.416 0 00-.367-.208.389.389 0 00-.142.025l-2.075.833a6.088 6.088 0 00-1.408-.817l-.317-2.208a.406.406 0 00-.408-.35H8.334a.406.406 0 00-.408.35l-.317 2.208a6.4 6.4 0 00-1.408.817l-2.079-.833a.472.472 0 00-.15-.025.413.413 0 00-.35.208L1.95 7.275a.411.411 0 00.1.533l1.759 1.376a6.609 6.609 0 00-.058.817 6.609 6.609 0 00.058.817L2.05 12.192a.42.42 0 00-.1.533l1.672 2.884a.416.416 0 00.367.208.389.389 0 00.142-.025l2.075-.833a6.088 6.088 0 001.408.817l.308 2.208a.406.406 0 00.408.35h3.333a.406.406 0 00.408-.35l.317-2.208a6.4 6.4 0 001.408-.817l2.075.833a.472.472 0 00.15.025.413.413 0 00.358-.208l1.667-2.883a.42.42 0 00-.1-.533zm-1.65-1.425a4.452 4.452 0 01.042.608c0 .175-.017.358-.042.608l-.117.942.742.583.9.7-.583 1.008-1.062-.424-.867-.35-.75.567a4.881 4.881 0 01-1.042.608l-.883.358-.133.942-.163 1.125H9.422l-.158-1.125-.142-.942-.883-.358a4.728 4.728 0 01-1.017-.592l-.763-.583-.883.358-1.054.425-.583-1.008.9-.7.742-.583-.117-.942c-.025-.258-.042-.45-.042-.617s.017-.358.042-.608l.117-.942-.747-.583-.9-.7.588-1.008 1.058.425.867.35.745-.567a4.881 4.881 0 011.042-.608l.888-.359.133-.942.167-1.124h1.158l.158 1.125.133.942.883.358a4.728 4.728 0 011.025.592l.758.583.883-.358 1.058-.425.583 1.008-.892.708-.742.583.117.942zM10 6.667A3.333 3.333 0 1013.333 10 3.332 3.332 0 0010 6.667zm0 5A1.667 1.667 0 1111.667 10 1.672 1.672 0 0110 11.667z"/>',
    lastMod: '1731744184945.4092'
  }
})
