/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zones': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M4 4h7V2H4a2.006 2.006 0 00-2 2v7h2zm16-2h-7v2h7v7h2V4a2.006 2.006 0 00-2-2zm0 18h-7v2h7a2.006 2.006 0 002-2v-7h-2zM4 13H2v7a2.006 2.006 0 002 2h7v-2H4z" fill="#009bd4"/>',
    lastMod: '1731744184945.4092'
  }
})
