//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import NavigationMenu from '~/components/menu/NavigationMenu.vue'

export default {
  components: { NavigationMenu },
  middleware: ['logged'],
  data() {
    return {
      appclass: {},
      // blReleases: false,
    }
  },
  head() {
    return {
      title: this.appName + (this.$store.state.layout.title || ''),
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.favicon,
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isInMaintenanceMode']),
    favicon() {
      return (
        this.$store.state.localStorage.userData?.Personalization?.Favicon ||
        '/BluConsole.png'
      )
    },
    appName() {
      return this.$store.state.localStorage.userData?.Personalization?.AppName
        ? this.$store.state.localStorage.userData.Personalization.AppName +
            ' - '
        : ''
    },
  },
  created() {
    if (this.isInMaintenanceMode) {
      this.$user.clearLocalStorage()
      this.$router.push('/login')
    }
    // setTimeout(() => {
    //   this.$nextTick(() => {
    //     !this.$cookies.get('bl-releases') && this.showBlReleasesModal()
    //   })
    // }, 2000)
  },
  methods: {
    // showBlReleasesModal() {
    //   this.$cookies.set('bl-releases', 1, {
    //     path: '/',
    //     maxAge: 60 * 60 * 24 * 14,
    //   })
    //   this.blReleases = true
    // },
  },
}
