import Vue from 'vue'
const validators = require('vuelidate/lib/validators')

export default (_, inject) => {
  const i18n = _.store.$i18n

  const validate = {
    required(message) {
      return (v) =>
        validators.required(v) || message || i18n.t('validation_new.required')
    },
    email(message) {
      return (v) =>
        validators.email(v) || message || i18n.t('validation_new.email')
    },
    numeric(message) {
      return (v) =>
        validators.numeric(v) || message || i18n.t('validation_new.numeric')
    },
    maxLength(max, message) {
      return (v) =>
        validators.maxLength(max)(v) ||
        message ||
        i18n.t('validation_new.maxLengthOf') +
          ' ' +
          max +
          ' ' +
          i18n.t('validation_new.characters')
    },
    minLength(min, message) {
      return (v) =>
        validators.minLength(min)(v) ||
        message ||
        i18n.t('validation_new.minLengthOf') +
          ' ' +
          min +
          ' ' +
          i18n.t('validation_new.characters')
    },
    minValue(min, message) {
      return (v) =>
        validators.minValue(min)(v) ||
        message ||
        i18n.t('validation_new.minValue') + min
    },
    maxValue(min, message) {
      return (v) =>
        validators.maxValue(min)(v) ||
        message ||
        i18n.t('validation_new.maxValue') + min
    },
    repeatPassword(pass, message) {
      return (v) =>
        v === pass || message || i18n.t('validation_new.repeatPassword')
    },
    clearErrorMsg(errorsRefer, errorName) {
      return (v) => {
        errorsRefer[errorName] = []
        return true
      }
    },
    invalidCharacter() {
      return (v) => {
        const regex = /^[^\s&<>]*$/
        if (!regex.test(v)) {
          return i18n.t('validation_new.notValidCharacterUsed')
        }
        return true
      }
    },
    validRangeByRefers(
      refs,
      minRefsName,
      maxRefsName,
      isMin = true,
      closedRange = true
    ) {
      return () => {
        if (!refs?.[minRefsName] || !refs?.[maxRefsName]) {
          return i18n.t('validation_new.notValidRange')
        }
        const currentRefsName = isMin ? minRefsName : maxRefsName
        const oppositeRefsName = isMin ? maxRefsName : minRefsName
        if (closedRange) {
          if (
            String(refs?.[oppositeRefsName]?.value ?? '').length &&
            !String(refs?.[currentRefsName]?.value ?? '').length
          ) {
            return i18n.t('validation_new.required')
          }
          if (
            String(refs?.[oppositeRefsName]?.value ?? '').length &&
            String(refs?.[currentRefsName]?.value ?? '').length &&
            (refs?.[minRefsName]?.value ?? 0) >
              (refs?.[maxRefsName]?.value ?? 0)
          ) {
            return i18n.t('validation_new.notValidRange') + ' (MIN>MAX)'
          }
        }
        return true
      }
    },
    triggerValidFieldByRefer(refs, referName) {
      Vue.nextTick(() => {
        refs?.[referName].validate?.(true)
      })
    },
  }
  inject('v', validate)
}
