export default {
  methods: {
    setDefaultPersonalization() {
      const userData = this.$formHelpers.merge(
        {},
        this.$store.state.localStorage.userData,
        {
          Personalization: {
            Logo:
              this.$store.state.localStorage.userData?.Personalization?.Logo ||
              require('~/static/logo.svg'),
            Favicon:
              this.$store.state.localStorage.userData?.Personalization
                ?.Favicon || '/BluConsole.png',
          },
        }
      )
      this.$store.commit('localStorage/setUserData', userData)
    },
    onPinCodeChange(e, qrPinCode) {
      const key = e.keyCode || e.charCode
      if (key !== 8 && key !== 46) {
        if (/^\D$/.test(e.key) || qrPinCode.length >= 4) {
          e.preventDefault()
        }
      }
    },
  },
}
