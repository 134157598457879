export default ({ app, store }, inject) => {
  const user = {
    name() {
      return store.state.user.user.Name
    },
    avatar() {
      return store.state.user.user.Avatar
    },
    email() {
      return store.state.user.user.Email
    },
    hasAccess(object, action) {
      return store.getters['user/hasAccess'](object, action)
    },
    clearLocalStorage() {
      localStorage.removeItem('logged')
      localStorage.removeItem('userEmail')
      localStorage.removeItem('userTheme')
      localStorage.removeItem('privileges')
      localStorage.removeItem('userId')
      localStorage.removeItem('organizationModule')
      localStorage.removeItem('userRoles')
      localStorage.removeItem('passwordExpiresIn')
      store.commit('localStorage/setUserData')
      store.commit('user/setPrivileges')
    },
    async logOut() {
      await app.$axios
        .get('/account/logout/')
        .then(() => {
          store.commit('user/setUser')
          user.clearLocalStorage()
          app.router.push('/login/')
        })
        .catch(() => {})
    },
  }
  inject('user', user)
}
