export const state = () => {
  return {
    activeDevices: [],
  }
}

export const mutations = {
  setActiveDevices(state, activeDevices) {
    state.activeDevices = activeDevices
  },
}

export const getters = {
  getActiveDevices(state) {
    return state.activeDevices
  },
}

export const actions = {
  async GetActiveDevicesData({ commit }, selectedYear) {
    const filterParams = { StatusActiveSince: selectedYear }
    await this.$axios({
      method: 'get',
      url: 'loggers/0/active-devices/',
      params: Object.assign({}, filterParams),
    })
      .then((response) => {
        commit('setActiveDevices', response.data.Result)
      })
      .catch((error) => {
        if (!error.response) {
          return error
        }
        if (error.response.data.GlobalError) {
          this.GlobalError = error.response.data.GlobalError
        }
      })
  },
}
