/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'not-checked': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 0h20v20H0zm0 0h20v20H0z" fill="none"/><path pid="1" d="M10 1.667A8.333 8.333 0 1018.333 10 8.336 8.336 0 0010 1.667zm0 15A6.667 6.667 0 1116.667 10 6.665 6.665 0 0110 16.667z" fill="#ccc"/><path pid="2" d="M7.278 6.317L14 12.898l-1.233 1.269L6.103 7.5z" fill="#ccc"/><path pid="3" d="M6.126 13.015l6.581-6.722 1.269 1.233-6.667 6.664z" fill="#ccc"/>',
    lastMod: '1731744184945.4092'
  }
})
