import moment from 'moment'

export const state = () => {
  return {
    // unAuthRoutes: [
    //   '/login',
    //   '/register',
    //   '/forgot-password',
    //   '/forgot-password/reset',
    // ],
    maintenanceEndDate: '2020-10-02 14:00:00',
    // maintenanceEndDate: undefined,
    // test deploy
  }
}

export const mutations = {
  setMaintenanceEndDate(state, status) {
    state.maintenanceEndDate = status
  },
}

export const actions = {
  logout: () => {},
}

export const getters = {
  isInMaintenanceMode(state) {
    const endDate = moment
      .utc(state.maintenanceEndDate)
      .local()
      .format('YYYY-MM-DD HH:mm:ss')

    return endDate > moment().format('YYYY-MM-DD HH:mm:ss')
  },
}
