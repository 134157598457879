//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiAlertCircle } from '@mdi/js'
import isEmpty from 'lodash/isEmpty'
export default {
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: { mdiAlertCircle },
    }
  },
  computed: {
    isEmpty() {
      return isEmpty(this.errors)
    },
  },
}
