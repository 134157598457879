export default function ({ isHMR, app }) {
  const browserLangLocale = navigator.language || navigator.userLanguage
  const preferredLang = browserLangLocale.split('-')
  const hostname = window.location.hostname

  const customTranslatedDomains = {
    tracecold: 'hubone',
    thermolabo: 'thermola',
    c2ai: 'thermola',
  }

  for (const i in customTranslatedDomains) {
    if (hostname.includes(i)) {
      const company = customTranslatedDomains[i].toUpperCase()
      const companyLangCode = preferredLang[0] + '-' + company

      for (const j in app.i18n.locales) {
        if (app.i18n.locales[j].code === companyLangCode) {
          app.i18n.setLocale(companyLangCode)
          return
        }
      }
    }
  }

  for (const j in app.i18n.locales) {
    if (app.i18n.locales[j].code === browserLangLocale) {
      app.i18n.setLocale(browserLangLocale)
      return
    }
  }
}
