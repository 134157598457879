export const state = () => {
  return {
    changeLogs: {},
  }
}

export const mutations = {
  setChangeLogs(state, changeLogs) {
    state.changeLogs = changeLogs
  },
}

export const getters = {
  getChangeLogs(state) {
    return state.changeLogs
  },
}

export const actions = {
  async GetChangeLogs(state) {
    await this.$axios
      .get('/change-logs/')
      .then((response) => {
        if (response.data.Result.Rows != null) {
          state.commit('setChangeLogs', response.data)
        }
      })
      .catch(() => {})
  },
}
