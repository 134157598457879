/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'menu-left-objects': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g data-name="Layer 2"><g data-name="Layer 1" fill="none" stroke="currentColor" stroke-miterlimit="10"><circle pid="0" cx="12" cy="4" r="1.5"/><circle pid="1" cx="12" cy="4" r="3.5"/><circle pid="2" cx="4" cy="12" r="1.5"/><circle pid="3" cx="4" cy="12" r="3.5"/><path pid="4" d="M.51 7.75A7.48 7.48 0 017.75.51M8.25 15.49a7.48 7.48 0 007.24-7.24" stroke-linecap="round"/></g></g>',
    lastMod: '1731744184945.4092'
  }
})
