import moment from 'moment'

export default ({ store }, inject) => {
  /* TODO: move into separate file */
  const saveAs = function (uri, filename) {
    const link = document.createElement('a')
    if (typeof link.download === 'string') {
      document.body.appendChild(link) // Firefox requires the link to be in the body
      link.download = filename
      link.href = uri
      link.click()
      document.body.removeChild(link) // remove the link when done
    } else {
      location.replace(uri)
    }
  }
  const createBlob = function (file, type) {
    const blob = new Blob([s2ab(atob(file))], {
      type,
    })
    return URL.createObjectURL(blob)
  }
  const s2ab = function (s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 255
    return buf
  }

  /* TODO: move into separate file */
  const hasPermissions = function (resource, privilege) {
    const localPrivileges = store.getters['user/getPrivileges'] || {}

    if (Object.keys(localPrivileges).includes(resource)) {
      if (localPrivileges[resource].includes(privilege)) {
        return true
      }
    }
    return false
  }
  const setUserData = function (userData) {
    // TODO: fix when Back end is done
    const theme = {
      primary: '#009BD4',
      secondary: '#737373',
      accent: '#009BD4',
      error: '#BE3939',
      info: '#009BD4',
      success: '#5CBD55',
      warning: '#F7A321',

      PageBackgroundColor: '#FFFFFF',
      SecondaryPageBackgroundColor: '#FAFAFA',
      LinkColor: '#009BD4',
      SelectedColor: '#E1EEF2',
      DisabledColor: '#F2F2F2',
      FormBorderColor: '#CCCCCC',
      FormPlaceholderColor: '#B3B3B3',
      DisabledTextColor: '#737373', // dasdsadsd
      ContentTextColor: '#1A1A1A',
      GreenStatusColor: '#5CBD55',
      OrangeStatusColor: '#F7A321',
      RedStatusColor: '#BE3939',
    }

    localStorage.setItem('logged', '1')
    localStorage.setItem('userEmail', userData.Email)
    localStorage.setItem('privileges', JSON.stringify(userData.Privileges))
    // localStorage.setItem('userTheme', resp.Result.Theme)
    localStorage.setItem(
      'organizationModule',
      JSON.stringify(userData.OrganizationsPerModule)
    )
    localStorage.setItem('userRoles', JSON.stringify(userData.Roles))
    localStorage.setItem('userTheme', JSON.stringify(theme))

    store.dispatch('user/loadUserData', userData.Email)
    // store.dispatch('user/saveTheme', resp.Result.Theme)
    store.dispatch('user/saveTheme', theme)
    store.commit('localStorage/setUserData', userData)
  }

  /* TODO: move into separate file */
  const formatToLocalDate = function (input, dateFormat = 'YYYY-MM-DD HH:mm') {
    const utcDate = this.$moment.utc(input).toDate()
    return this.$moment(utcDate).local().format(dateFormat)
  }
  const formatToLocalTime = function (input) {
    const hours = input.split(':')[0]
    const minutes = input.split(':')[1]

    const d = new Date()
    const localTime = moment(d.setHours(hours, minutes, 0, 0)).format(
      'YYYY-MM-DD HH:mm'
    )

    const utcDate = this.$moment.utc(localTime).toDate()
    return this.$moment(utcDate).local().format('HH:mm')
  }
  const formatToTimeStamp = function (input) {
    return new Date(input).getTime()
  }

  /* TODO: move into separate file */
  inject('createBlob', createBlob)
  inject('s2ab', s2ab)
  inject('saveAs', saveAs)

  /* TODO: move into separate file */
  inject('hasPermissions', hasPermissions)
  inject('setUserData', setUserData)

  /* TODO: move into separate file */
  inject('formatToLocalDate', formatToLocalDate)
  inject('formatToLocalTime', formatToLocalTime)
  inject('formatToTimeStamp', formatToTimeStamp)
}
