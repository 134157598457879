//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'NotificationBar',
  data() {
    return {
      alert: false,
      alertMessage: '',
    }
  },
  watch: {
    alert(val) {
      if (!val) {
        localStorage.removeItem('passwordExpiresIn')
      }
    },
  },
  mounted() {
    const days = localStorage.getItem('passwordExpiresIn')
    if (days) {
      let message = ''
      if (days === '1') {
        message += 'Your password will expire in ' + days + ' day'
      } else {
        message += 'Your password will expire in ' + days + ' days'
      }
      this.showNotification(message)
    }
  },
  methods: {
    showNotification(message) {
      this.alertMessage = message
      this.alert = true
    },
  },
}
