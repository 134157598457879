//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiPowerStandby } from '@mdi/js'
import SubscriptionSVG from '~/components/svg/SubscriptionSVG.vue'

export default {
  components: { SubscriptionSVG },
  props: {
    appclass: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      generalMenu: [],
      managementMenu: [],
      privileges: {},
      alerts: 0,
      intervals: {},
      configDrawer: {
        'mobile-breakpoint': this.$vuetify.breakpoint.mobileBreakpoint,
        width: null,
        fixed: false,
        'mini-variant': true,
        isMobile: true,
        right: false,
      },
      drawer: true,
      email: localStorage.getItem('userEmail'),
      icons: { mdiPowerStandby },
      organizationModule: localStorage.getItem('organizationModule'),
      userRoles: localStorage.getItem('userRoles'),
      isInnolabelClient: false,
    }
  },
  computed: {
    filteredGeneralMenu() {
      const filteredMenu = this.generalMenu.map((item) => {
        if (item.iconsSelector) {
          item.iconsSelector.updateIconData(item)
        }
        if (item.resource !== undefined) {
          item.isVisible = this.$hasPermissions(item.resource, 'read')
        }
        return item
      })

      return filteredMenu.filter((item) => {
        return item.isVisible
      })
    },
    filteredManagementMenu() {
      const filteredMenu = this.managementMenu.map((item) => {
        if (item.resource !== undefined) {
          item.isVisible = this.$hasPermissions(item.resource, 'read')
        }
        return item
      })

      return filteredMenu.filter((item) => {
        return item.isVisible
      })
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
    organizationLogo() {
      const t = this.$store.state.localStorage.userData?.Personalization?.Logo

      if (t) {
        return t
      }
      return '/logo.svg'

      // return this.$store.state.localStorage.userData?.Personalization?.Logo
    },
    dynamicHref() {
      this.checkIfUserIsInnolabelClient()
      return this.isInnolabelClient
        ? 'https://innolabel.eu/contact/'
        : 'https://blulog.eu/instructions-manual-bluconsole-introduction/'
    },
  },
  watch: {
    isMobile() {
      this.updateDrawer()
    },
    drawer() {
      const appClass = Object.assign({}, this.appclass)
      appClass['bl-menu-drawer-open'] = this.drawer
      this.$emit('update:appclass', appClass)
    },
  },
  created() {
    this.$nuxt.$on('closeNavigationDrawer', (res) => {
      this.configDrawer['mini-variant'] = true
    })
    this.generalMenu = [
      // {
      //   location: '/',
      //   name: 'Dashboard',
      //   icon: 'dashboard',
      //   isVisible: true,
      // },
      {
        location: '/alerts/',
        name: this.$t('sidebar.alerts'),
        icon: 'alerts',
        svgColors: 'transparent inherit',
        resource: 'alerts',
        isVisible: undefined,
        class: ['alert-color'],
        iconsSelector: {
          updateIconData: (item) => {
            const selector = item.iconsSelector
            selector.itemData.isActive =
              this.$route.path.indexOf(item.location) === 0
            const iconIndex =
              (selector.itemData.isActive ? 'active' : 'not_active') +
              (this.alerts > 0 ? '_alarm' : '')
            if (selector.iconsData[iconIndex]) {
              selector.currentIndex = iconIndex
              item.icon = selector.iconsData[iconIndex].name
              item.svgColors = selector.iconsData[iconIndex].colors
              item.class = selector.iconsData[iconIndex].class
            }
          },
          currentIndex: null,
          itemData: {
            isActive: null,
          },
          iconsData: {
            active: {
              name: 'alerts',
              colors: 'transparent inherit',
              class: '',
            },
            active_alarm: {
              name: 'animated_alert_3',
              colors: 'inherit inherit',
              class: '',
            },
            not_active: {
              name: 'alerts',
              colors: 'transparent inherit',
              class: ['alert-color'],
            },
            not_active_alarm: {
              name: 'animated_alert_3',
              colors: 'inherit inherit',
              class: ['alert-color'],
            },
          },
        },
      },
      {
        location: '/measurements/',
        name: this.$t('sidebar.measurements'),
        icon: 'measurements2',
        resource: 'measurements',
        isVisible: undefined,
        class: undefined,
      },
    ]

    this.managementMenu = [
      {
        location: '/roles/',
        name: this.$t('sidebar_new.roles'),
        icon: 'roles',
        resource: 'rolesAndPermissions',
        isVisible: undefined,
      },
      {
        location: '/users/',
        name: this.$t('sidebar.users'),
        icon: 'users',
        resource: 'users',
        isVisible: undefined,
      },
      {
        location: '/organization/',
        name: this.$t('sidebar.organizations'),
        icon: 'organizations',
        resource: 'organizations',
        isVisible: undefined,
      },
      {
        location: '/hub/',
        name: this.$t('sidebar.hubs'),
        icon: 'router',
        resource: 'hubs',
        isVisible: undefined,
      },
      {
        location: '/loggers/',
        name: this.$t('sidebar.loggers'),
        icon: 'loggers',
        resource: 'loggers',
        isVisible: undefined,
      },
      {
        location: '/reports/',
        name: this.$t('sidebar.reports'),
        icon: 'report',
        resource: 'reports',
        isVisible: undefined,
      },
      {
        location: '/zone/',
        name: this.$t('sidebar.zones'),
        icon: 'zones',
        resource: 'zones',
        isVisible: undefined,
      },
      {
        location: '/notifications/',
        name: this.$t('sidebar.notificationTypes'),
        icon: 'notification-type',
        resource: 'notificationTypes',
        isVisible: undefined,
      },
      {
        location: '/schedule-plans/',
        name: this.$t('sidebar.schedulePlan'),
        icon: 'schedule-type',
        resource: 'schedulePlans',
        isVisible: undefined,
      },
      {
        location: '/audit-trail/',
        name: this.$t('sidebar.auditTrail'),
        icon: 'audit',
        resource: 'auditTrail',
        isVisible: undefined,
      },
      {
        location: '/objects/',
        name: this.$t('sidebar.objects'),
        svgColors: 'currentColor currentColor currentColor currentColor',
        icon: 'menu-left-objects',
        resource: 'objects',
        isVisible: undefined,
        fill: false,
        style: 'max-height: 20px; max-width: 20px;',
      },
    ]
    this.checkSubscription()
    this.checkGroupMonitoring()

    // TODO TO REMOVE ON IMPLEMENT ALERTS API; THIS IS TEST CODE FOR Marcin Gałęcki
    if (this.$route.query.alert_test !== undefined) {
      if (this.$route.query.alert_test === 'tremble') {
        this.generalMenu[0].iconsSelector.iconsData.active_alarm.name =
          'animated_tremble'
        this.generalMenu[0].iconsSelector.iconsData.not_active_alarm.name =
          'animated_tremble'
      } else {
        this.generalMenu[0].iconsSelector.iconsData.active_alarm.name =
          'animated_alert_3'
        this.generalMenu[0].iconsSelector.iconsData.not_active_alarm.name =
          'animated_alert_3'
      }
      this.loadAlerts()
      this.addIntervals('menu-alerts', 10000, this.loadAlerts)
    }
    // TODO TO ACTIVE ON IMPLEMENT ALERTS API
    // this.loadAlerts()
    // this.addIntervals('menu-alerts', 10000, this.loadAlerts)
    this.updateDrawer()
    this.$root.$refs.navigationMenu = this
  },
  beforeDestroy() {
    this.clearIntervals()
  },
  methods: {
    loadAlerts() {
      // TODO IMPLEMENT ALERTS API INSTEAD fakeApiAlerts();
      this.fakeApiAlerts().then((result) => {
        this.alerts = result
      })
    },
    addIntervals(index, timeout, callback) {
      if (this.intervals[index] === undefined) {
        this.intervals[index] = setInterval(() => {
          callback()
        }, timeout)
      }
    },
    clearIntervals(index = null) {
      if (index === null) {
        for (const key in this.intervals) {
          clearInterval(this.intervals[key])
        }
      } else {
        clearInterval(this.intervals[index])
      }
    },
    // TODO TO REMOVE ON IMPLEMENT ALERTS API
    fakeApiAlerts() {
      const currentAllerts = this.alerts
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(currentAllerts >= 1 ? 0 : currentAllerts + 1)
        }, 2000)
      })
    },
    updateDrawer() {
      if (this.isMobile) {
        this.configDrawer = {
          'mobile-breakpoint': this.$vuetify.breakpoint.mobileBreakpoint,
          width: '320',
          fixed: true,
          'mini-variant': false,
          isMobile: true,
          right: true,
        }
        this.drawer = false
      } else {
        this.configDrawer = {
          'mobile-breakpoint': this.$vuetify.breakpoint.mobileBreakpoint,
          width: '240',
          fixed: false,
          'mini-variant': false,
          isMobile: false,
          right: false,
        }
        this.drawer = true
      }
    },
    checkSubscription() {
      let isAdmin = false
      const roles = JSON.parse(this.userRoles)
      for (const property in roles) {
        if (roles[property] === 'Admin') {
          isAdmin = true
        }
      }
      if (this.organizationModule !== '' && isAdmin) {
        if (this.organizationModule.search('subscription') > 0) {
          this.managementMenu.splice(5, 0, {
            location: '/subscribed-devices/',
            name: this.$t('sidebar.subscription'),
            icon: 'subscription',
            resource: 'loggers',
            isVisible: undefined,
          })
        }
      }
    },
    checkGroupMonitoring() {
      if (this.organizationModule !== '') {
        if (this.organizationModule.search('groupMonitoring') > 0) {
          this.managementMenu.splice(this.managementMenu.length, 0, {
            location: '/group-monitoring/',
            name: this.$t('sidebar.groupMonitoring'),
            icon: 'menu-left-objects',
            resource: 'groupMonitoring',
            isVisible: undefined,
            fill: false,
            style: 'max-height: 20px; max-width: 20px;',
          })
        }
      }
    },
    checkIfUserIsInnolabelClient() {
      const userRolesObj = JSON.parse(this.userRoles)
      Object.keys(userRolesObj).forEach((key) => {
        if (key === '480' || key === '6848' || key === '3111') {
          this.isInnolabelClient = true
        }
      })
    },
  },
}
