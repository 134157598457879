export const state = () => {
  return {
    user: null,
    email: null,
    theme: null,
    privileges: null,
    organizationModule: null,
    userRoles: null,
  }
}

export const mutations = {
  setUser(state, user) {
    state.user = user || null
  },
  setEmail(state, user) {
    state.email = user || null
  },
  setTheme(state, theme) {
    state.theme = theme || null
  },
  setPrivileges(state, privileges) {
    state.privileges = privileges || null
  },
  setOrganizationsModule(state, organizationModule) {
    state.organizationModule = organizationModule || null
  },
  setUserRoles(state, userRoles) {
    state.userRoles = userRoles || null
  },
}

export const getters = {
  getUser(state) {
    return {
      user: state.user,
      email: state.email,
    }
  },
  getTheme(state) {
    return state.theme
  },
  getPrivileges(state) {
    return state.privileges
  },
  getOrganizationModule(state) {
    return state.organizationModule
  },
  getUserRoles(state) {
    return state.userRoles
  },
  // hasAccess(state) {
  //   return (object, action) => {
  //     const perm = state.permissions[object];
  //     if (!perm) {
  //       return false;
  //     }
  //     return perm.includes(action);
  //   };
  // }
}

export const actions = {
  async loadUserData(store, result) {
    await store.commit('setEmail', result)
  },
  async saveTheme(store, result) {
    await store.commit('setTheme', result)
  },
  async savePrivileges(store, result) {
    await store.commit('setPrivileges', result)
  },
  async saveOrganizationModule(store, result) {
    await store.commit('setOrganizationsModule', result)
  },
  async saveUserRoles(store, result) {
    await store.commit('setUserRoles', result)
  },
}
