export const state = () => {
  return {
    title: null,
  }
}

export const mutations = {
  setTitle(state, title) {
    state.title = title || null
  },
}
