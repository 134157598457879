//
//
//
//
//
//
//
//
//
//

import global from '~/mixins/global.js'
export default {
  mixins: [global],
  data: () => {
    return {
      globalError: '' || null,
      pageLoaded: false,
    }
  },
  fetch() {
    this.$store.commit('localStorage/setUserData', {
      Personalization: {
        Logo: null,
        Favicon: null,
      },
    })

    if (
      !window.location.hostname.includes('bluconsole.com') &&
      !window.location.hostname.includes('localhost')
    ) {
      this.$axios
        .$get('/logo/')
        .then((resp) => {
          if (resp.Result.ShowLogo) {
            this.$store.commit('localStorage/setUserData', {
              Personalization: {
                Logo: resp.Result?.Logo ?? null,
                Favicon: resp.Result?.FavIcon ?? null,
              },
            })
          } else {
            this.$store.commit('localStorage/setUserData')
          }
        })
        .catch((error) => {
          if (!error.response) {
            return error
          }

          if (error.response.data.GlobalError) {
            this.globalError = error.response.data.GlobalError
          }
        })
        .then(() => {
          this.setDefaultPersonalization()
          this.pageLoaded = true
        })
    } else {
      this.setDefaultPersonalization()
      this.pageLoaded = true
    }
  },
  head() {
    return {
      title: this.$store.state.layout.title,
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.favicon,
        },
      ],
    }
  },
  computed: {
    favicon() {
      return this.$store.state.localStorage.userData?.Personalization?.Favicon
    },
  },
}
