export default function ({ store, redirect, $vuetify, $axios, route }) {
  if (!localStorage.getItem('logged')) {
    redirect('/login/')
  }
  if (localStorage.getItem('userTheme')) {
    $vuetify.theme.themes.light = JSON.parse(
      localStorage.getItem('userTheme').toLowerCase()
    )
  }

  if (localStorage.getItem('privileges')) {
    store.dispatch(
      'user/savePrivileges',
      JSON.parse(localStorage.getItem('privileges'))
    )
  }

  if (route.query) {
    if (typeof route.query === 'string') {
      route.query = sanitizeQuery(route.query)
    } else if (typeof route.query === 'object') {
      for (const key in route.query) {
        if (typeof route.query[key] === 'string') {
          route.query[key] = sanitizeQuery(route.query[key])
        }
      }
    }
  }
  $axios.onRequest((config) => {
    if (config.data) {
      if (typeof config.data === 'string') {
        config.data = sanitizeQuery(config.data)
      } else if (typeof config.data === 'object') {
        for (const key in config.data) {
          if (typeof config.data[key] === 'string') {
            config.data[key] = sanitizeQuery(config.data[key])
          }
        }
      }
    } else if (config.params) {
      if (typeof config.params === 'string') {
        config.params = sanitizeQuery(config.params)
      } else if (typeof config.params === 'object') {
        for (const key in config.params) {
          if (typeof config.params[key] === 'string') {
            config.params[key] = sanitizeQuery(config.params[key])
          }
        }
      }
    }
    return config
  })
}

function sanitizeQuery(value) {
  return value
    .replace(/javascript:|window\.|document\.|alert\(/gi, '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
}
