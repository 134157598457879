/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'measurements': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0zm0 0h24v24H0z" fill="none"/><path pid="1" d="M1 9l2 2a12.729 12.729 0 0118 0l2-2A15.566 15.566 0 001 9zm8 8l3 3 3-3a4.237 4.237 0 00-6 0zm-4-4l2 2a7.074 7.074 0 0110 0l2-2a9.91 9.91 0 00-14 0z" fill="#737373"/>',
    lastMod: '1731744184945.4092'
  }
})
