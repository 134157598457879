/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'alerts': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0z" fill="none"/><path pid="1" d="M10.01 20.51a1.99 1.99 0 003.98 0zM12 5.5a5 5 0 015 5v7H7v-7a5 5 0 015-5zM12 1a1.5 1.5 0 00-1.5 1.5v1.17A6.993 6.993 0 005 10.5v6l-2 2v1h18v-1l-2-2v-6a6.993 6.993 0 00-5.5-6.83V2.5A1.5 1.5 0 0012 1z" fill="#be3939"/>',
    lastMod: '1731744184941.4092'
  }
})
