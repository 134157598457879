export const state = () => {
  return {
    routes: {
      alerts: null,
      measurementsRF: null,
      measurementsNFC: null,
      users: null,
      organization: null,
      loggers: null,
      schedulePlans: null,
      humanAlerts: null,
      humans: null,
      objects: null,
    },
  }
}

export const mutations = {
  setBackRoutes(state, pageData) {
    state.routes[pageData.page] = pageData.backUrl || null
  },
}

export const getters = {
  getBackRoutes(state) {
    return state.routes
  },
}

export const actions = {
  async updateBackRoutes(store, pageData) {
    await store.commit('setBackRoutes', pageData)
  },
}
